import React from "react"
import {
	Button, Row
}from "antd"
import {
	Link
}from "react-router-dom"
import {
	useSelector
}from "react-redux"
import i18n from "./localization"
import './style.css'

const NotFound = () => {
	const isRu = useSelector(state => state.globalReducer.isRu)
	const isLoading  = useSelector((state) => state.userReducer.isLoading)
	return (
		isLoading ? null :
		<div className="not-found">
			<svg
				fill="none"
				height="434"
				viewBox="0 0 797 434"
				width="797"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M416 1.14575C319.5 -4.35375 247.5 8.64575 165 68.6457C82.4999 128.646 103 282.146 121.5 329.146C140 376.146 212.5 399.146 256.5 412.646C300.5 426.146 600 452.146 648.5 412.646C697 373.146 732 289.646 734 222.146C735.992 154.905 690.28 139.76 637.116 91.2094C636.71 90.8387 636.258 90.4885 635.798 90.1884C552.505 35.9137 512.23 6.62985 416 1.14575Z"
					fill="#50CFC9"
					fillOpacity="0.17"
				/>
				<path
					d="M356 140.146H500C505.523 140.146 510 144.623 510 150.146V394.146C510 399.668 505.523 404.146 500 404.146H316.5C310.977 404.146 306.5 399.668 306.5 394.146V188.146L356 140.146Z"
					fill="white"
				/>
				<path
					d="M356 140.146H500C505.523 140.146 510 144.623 510 150.146V394.146C510 399.668 505.523 404.146 500 404.146H316.5C310.977 404.146 306.5 399.668 306.5 394.146V188.146M356 140.146V178.146C356 183.668 351.523 188.146 346 188.146H306.5M356 140.146L306.5 188.146"
					stroke="#8289B7"
					strokeWidth="2"
				/>
				<line
					stroke="#CEEEFC"
					strokeWidth="3"
					x1="367"
					x2="446"
					y1="283.146"
					y2="283.146"
				/>
				<line
					stroke="#939AB2"
					strokeWidth="1.5"
					x1="324"
					x2="486"
					y1="318.896"
					y2="318.896"
				/>
				<line
					stroke="#939AB2"
					strokeWidth="1.5"
					x1="324"
					x2="486"
					y1="340.896"
					y2="340.896"
				/>
				<line
					stroke="#939AB2"
					strokeWidth="1.5"
					x1="324"
					x2="405"
					y1="363.896"
					y2="363.896"
				/>
				<circle
					cx="367"
					cy="211.646"
					fill="#939AB2"
					r="4"
				/>
				<circle
					cx="448"
					cy="211.646"
					fill="#939AB2"
					r="4"
				/>
				<path
					d="M377 237.146C388 222.146 427 221.645 440 237.146"
					stroke="#939AB2"
					strokeWidth="2"
				/>
				<path
					d="M510.303 244.248C519.282 262.282 527.236 269.056 548.303 273.248"
					stroke="#8289B7"
					strokeWidth="2"
				/>
				<path
					d="M306 243.146C285.401 236.9 275.409 229.758 260.716 212.058"
					stroke="#8289B7"
					strokeWidth="2"
				/>
				<line
					stroke="#939AB2"
					strokeWidth="2"
					x1="263.006"
					x2="250.006"
					y1="241.751"
					y2="118.751"
				/>
				<rect
					fill="#FDB11C"
					height="83.4739"
					rx="10"
					transform="rotate(-6.15381 251.669 53.458)"
					width="158.561"
					x="251.669"
					y="53.458"
				/>
				<rect
					height="81.4739"
					rx="9"
					stroke="#8289B7"
					strokeWidth="2"
					transform="rotate(-6.15381 242.77 44.345)"
					width="156.561"
					x="242.77"
					y="44.345"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="165"
					x="612"
					y="116.646"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="194"
					x="540"
					y="154.646"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="165"
					x="632"
					y="201.646"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="165"
					y="232.646"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="194"
					x="48"
					y="270.646"
				/>
				<rect
					fill="white"
					height="31"
					rx="15.5"
					width="165"
					x="20"
					y="317.646"
				/>
				<path
					d="M313.159 88.4533L307.492 89.0639L308.279 96.3716L300.425 97.218L299.638 89.9103L280.896 91.9297L280.312 86.5111L294.339 62.6711L302.79 61.7605L289.833 84.3285L299.179 83.3215L298.477 76.8091L306.083 75.9896L306.785 82.5019L312.452 81.8912L313.159 88.4533ZM331.333 94.491C328.45 94.8017 325.805 94.3659 323.398 93.1836C320.99 92.0014 318.996 90.1377 317.414 87.5925C315.862 85.0106 314.884 81.8471 314.481 78.1021C314.077 74.3571 314.36 71.0746 315.33 68.2544C316.33 65.3975 317.88 63.1351 319.98 61.4672C322.08 59.7993 324.572 58.81 327.455 58.4993C330.338 58.1886 332.984 58.6244 335.391 59.8067C337.798 60.9889 339.778 62.871 341.33 65.4529C342.912 67.9981 343.904 71.1432 344.308 74.8882C344.711 78.6332 344.413 81.9341 343.414 84.791C342.444 87.6111 340.908 89.8552 338.808 91.5231C336.708 93.191 334.217 94.1803 331.333 94.491ZM330.599 87.6804C332.721 87.4519 334.279 86.3619 335.276 84.4106C336.306 82.4557 336.615 79.5726 336.205 75.7613C335.794 71.95 334.877 69.1991 333.455 67.5084C332.065 65.8142 330.31 65.0814 328.189 65.3099C326.101 65.5349 324.542 66.6248 323.512 68.5797C322.516 70.5311 322.223 73.4124 322.633 77.2236C323.044 81.0349 323.944 83.7877 325.334 85.4819C326.756 87.1725 328.512 87.9054 330.599 87.6804ZM381.174 81.1246L375.507 81.7352L376.294 89.0429L368.439 89.8893L367.652 82.5816L348.91 84.601L348.327 79.1824L362.353 55.3424L370.804 54.4318L357.848 76.9998L367.193 75.9928L366.492 69.4805L374.098 68.6609L374.799 75.1732L380.467 74.5626L381.174 81.1246Z"
					fill="white"
				/>
			</svg>
			<h3>
				{ i18n.header[isRu] }
			</h3>
			<p>
				{ i18n.message[isRu] }
			</p>
			<Link to="/">
				<span>
					{ i18n.back[isRu] }
				</span>
			</Link>
		</div>
	)
}

export default NotFound
