const i18n = {
	authErrorMessage: {
		"be" : "Памылка аўтэнтыфікацыі. Паспрабуйце яшчэ раз, альбо выканайце паўторны ўваход",
		"ru" : "Ошибка аутентификации. Попробуйте снова, либо выполните повторный вход"
	},
	responsewithStatusCode: {
		"be" : "Быў атрыманы адказ сервера са статусам {0}. Шлях запыту {1}.",
		"ru" : "Был получен ответ сервера со статусом {0}. Путь запроса {1}."
	},
	unknownResopnseError: {
		"be" : "Невядомая памылка. Шлях запыту {0}",
		"ru" : "Неизвестная ошибка. Путь запроса {0}"
	}

}

export default i18n