import axios from 'axios'
import message from '../components/SessionExpiration'
import i18n from './localization'

axios.defaults.baseURL =
	process.env.NODE_ENV === 'development'
		? process.env.SERVER_API_URL
		: `${ window.location.origin }/api`
axios.defaults.headers['Pragma'] = 'no-cache'
axios.defaults.headers['Cache-Control'] = 'no-cache, no-store'
axios.interceptors.request.use((config) => {
	const language = window.localStorage.getItem('language')
	if (language)
		config.headers['Accept-Language'] = language

	return config
}, (error) => {
	return Promise.reject(error)
})

axios.interceptors.request.use((config) => {
	if (!window.localStorage.getItem("IdCardBauth")){ //проверяем на то что идет работа с считывателем ID-карты
		const jsonUser = window.localStorage.getItem('user')
		const notUse = config.url.startsWith("http")
		if (jsonUser){
			const user = JSON.parse(jsonUser)
			config.headers.Authorization = `Bearer ${ user.token }`
			config.headers.SessionId = user.sessionId
		}
		if (notUse){
			delete config.headers["Authorization"]
			delete config.headers['Pragma']
			delete config.headers['Cache-Control']
			delete config.headers['SessionId']
		}
	}

	return config
}, (error) => {
	return Promise.reject(error)
})

axios.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config
	const locale = window.localStorage.getItem("language") ?? "ru"
	if(
		error.response?.status == 403 &&
		error.response?.data.errors == 'Activity timeout'
	){
		const jsonUser = window.localStorage.getItem('user')
		const idToken = window.localStorage.getItem("idToken")
		const user = JSON.parse(jsonUser)
		User.logout({
			idToken   : idToken,
			sessionId : user.sessionId,
			sessionLogout : true
		}).then((res) => {
			if(res){
				window.localStorage.removeItem("user")
				window.open("/", "_self")
			}
		})
	}
	else if (error.response?.status === 401 && !originalRequest._retry){
		originalRequest._retry = true
		const jsonUser = window.localStorage.getItem('user')
		const idToken = window.localStorage.getItem("idToken")
		if (jsonUser){
			const user = JSON.parse(jsonUser)
			return axios
				.post('/account/refresh', {
					token: user.token,

					//refreshToken: user.refreshToken,
					sessionId: user.sessionId
				})
				.then((res) => {
					if (res.status === 200){
						window.localStorage.setItem('user', JSON.stringify({
							userName     : res.data.userName,
							token        : res.data.token,
							refreshToken : res.data.refreshToken,
							sessionId    : res.data.sessionId
						}))
						// if (originalRequest.url.includes('user/logout')){
						// 	return User.logout({
						// 		idToken   : idToken,
						// 		sessionId : res.data.sessionId
						// 	})
						// }
						return axios(originalRequest)
					}
					return Promise.reject(error.response)
				})
				.catch((err) => {
					const authError = i18n.authErrorMessage[locale]

					writeInfoIntoDataResponseProperty(error, authError)
					return Promise.reject(err)
				})
		}
		else if (!originalRequest.url.includes('refreshUserData'))
			message()
	}
	else if (
		error.response?.status == 403 &&
		error.response?.data.errors == 'Invalid RefreshToken'
	){
		const jsonUser = window.localStorage.getItem('user')
		if (jsonUser)
			message()
	}
	
	else {
		const unknowErrorMessage =
			error.response?.status ?
				  i18n.responsewithStatusCode[locale].format(error.response?.status, error.config.url)
				: i18n.unknownResopnseError[locale].format(error.config.url)

		writeInfoIntoDataResponseProperty(error, unknowErrorMessage)

		return Promise.reject(error.response)
	}
})

const writeInfoIntoDataResponseProperty = (error, message) => {
	if (typeof error.response?.data === 'object')
		error.response.data.errors = error.response.data.errors ?? message
	else
		error.response ? error.response.data = { errors: message } : error.response = { data: { errors: message } }
}

const responseBody = (response) => response.data
export const requests = {
	getFile: (url, query = {}) => axios
		.get(url, {
			responseType : 'blob',
			params       : query
		})
		.then(responseBody),
	getLargeFile: (url, query = {}) => axios
		.get(url, {
			responseType : 'arraybuffer',
			params       : query
		})
		.then(responseBody),
	get           : (url) => axios.get(url).then(responseBody),
	getWithParams : (url, query = {}) => axios
		.get(url, {
			params: query
		})
		.then(responseBody),
	post : (url, body = {}) => axios.post(url, body).then(responseBody),
	postFile : (url, body = {}) => axios.post(url, body, {
		responseType: 'blob'
		}).then(responseBody),
	put  : (url, body = {}) => axios.put(url, body).then(responseBody),
	del  : (url, query = {}) => axios
		.delete(url, {
			data: query
		})
		.then(responseBody),
	postForm: (url, file) => {
		const formData = new FormData()
		formData.append('File', file)
		return axios
			.post(url, formData, {
				headers: {
					'Content-type': 'multipart/form-data'
				}
			})
			.then(responseBody)
	},
	postFormMany: (url, files) => {
		const fd = new FormData()
		files.forEach((file) => {
			fd.append('Files', file)
		})
		return axios
			.post(url, fd, {
				headers: {
					'Content-type': 'multipart/form-data'
				}
			})
			.then(responseBody)

		/* var results = []
		   files.forEach(file => {
			 results.push(postForm(url, file))
		   })
		   return results */
	}
}

const User = {
	login                     : (user) => requests.post('/account/login', user),
	belpochtaLogin            : (data) => requests.post('/externalLogin/belpochtaLogin', data),
	signlogin                 : (IdentificationNumber) => requests.post('/account/signlogin', IdentificationNumber),
	changeSign                : (data) => requests.post('/account/changesign', data),
	logout                    : (data) => requests.post('/account/logout', data),
	refreshUserData           : () => requests.get('/account/refreshUserData'),
	confirmPhone              : (data) => requests.post('/phone/confirmMobilePhone', data),
	getUserTypes              : (data) => requests.post('/account/getUserTypes', data),
	getRoles                  : (data) => requests.post('/account/getRoles', data),
	getIFULLoginParameters    : () => requests.get('/account/getIFULLoginParameters'),
	IFULLogin                 : (params) => requests.getWithParams('/account/IFULLogin', params),
	getIFULBauthParameters    : () => requests.get('/account/getIFULBauthParameters'),
	BauthInit                 : (params) => requests.getWithParams('/account/BauthInit', params),
	BauthProcess              : (params) => requests.getWithParams('/account/BauthProcess', params),
	DgInit                    : (params) => requests.getWithParams('/account/DgInit', params),
	DgRead                    : (params) => requests.getWithParams('/account/DgRead', params),
	DgRequest                 : (params) => requests.getWithParams('/account/DgRequest', params),
	BelpochtaLogin            : (params) => requests.getWithParams('/account/BelpochtaLogin', params),
	attachIdNumberFromIFUL    : params => requests.post('/account/attachIdNumberFromIFUL', params),
	attachIdNumberFromProfile : params => requests.post('/account/attachIdNumberFromProfile', params),
	detachIdNumber            : () => requests.post('/account/detachIdNumber'),

	//register;
	checkSystemEnvironment : () => requests.get('/account/CheckSystemEnvironment'),
	preChecking            : (user) => requests.post('/account/preChecking', user),
	citizenRegister        : (user) => requests.post('/account/citizenRegister', user),
	foreignRegister        : (user) => requests.post('/account/foreignRegister', user),
	callCenterRegister 	   : (user) => requests.post('/account/callCenterRegister', user),
	statelessRegister      : (user) => requests.post('/account/statelessRegister', user),
	individualRegister     : (user) => requests.post('/account/individualRegister', user),
	juridicalRegister      : (user) => requests.post('/account/juridicalRegister', user),
	foreignCompanyRegister : (user) => requests.post('/account/foreignCompanyRegister', user),

	//change;
	citizenChange        : (user) => requests.post('/account/citizenChange', user),
	foreignChange        : (user) => requests.post('/account/foreignChange', user),
	statelessChange      : (user) => requests.post('/account/statelessChange', user),
	individualChange     : (user) => requests.post('/account/individualChange', user),
	juridicalChange      : (user) => requests.post('/account/juridicalChange', user),
	foreignCompanyChange : (user) => requests.post('/account/foreignCompanyChange', user),

	//unblock;
	unblock           : (data) => requests.post('/unblock/unblock', data),
	unblockPreCheking : (data) => requests.post('/unblock/preChecking', data)
}

export default {
	User,
	requests
}
