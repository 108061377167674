import React, {
	useEffect
}from "react"
import {
	Layout, ConfigProvider
}from "antd"
import {
	useDispatch,
	useSelector
}from "react-redux"
import {
	hot, setConfig
}from "react-hot-loader/root"
import {
	Router, useRouteMatch
}from "react-router-dom"

import axios from "axios"

import history from "./helpers/history"

import "./index.css"
import "./App.css"
import "antd/lib/menu/style/index.css"
import Footer from "./components/Footer/index"
import byBY from 'antd/lib/locale/by_BY'
import ruRU from 'antd/lib/locale/ru_RU'
import moment from "moment"
import Navigation from "./containers/Menu/index"
import Routes from "./routes"
import CustomBreadcrumb from "./components/CustomBreadcrumb"

import Bowser from "bowser"
import ErrorBoundary from "./components/ErrorBoundary"
import {
	userActions
}from "./store/actions"
const { Content } = Layout

const App = () => {
	const { roles, systemEnvironment } = useSelector((state) => state.userReducer)
	const isRu = useSelector(state => state.globalReducer.isRu)

	const dispatch = useDispatch()

	useEffect(() => {
		isRu ? moment.locale('ru') : moment.locale('be')
	}, [isRu])

	useEffect(() => {
		dispatch(userActions.checkSystemEnvironment())
	}, [])

	useEffect(() => {
		if (systemEnvironment && systemEnvironment.testingEnvironment === false){
			gtag('js', new Date())
			gtag('config', 'G-TH9MT62EFH')
		}
	}, [systemEnvironment])

	/* useEffect(() => {
	   	let urlelem = window.location.href.split('/')
	   	if (urlelem[2] !== "127.0.0.1:8085"){
	   		const headers = axios.defaults.headers
	   		delete headers['Pragma']
	   		delete headers['Cache-Control']
	   		axios({
	   			data    : { ip_port: "93.125.24.8:8443" },
	   			headers : headers,
	   			method  : 'post',
	   			url     : 'http://127.0.0.1:8084/tls_init'
	   		}).then((resultCP) => {
	   			if (resultCP.data["error"] == "0")
	   				window.location.assign("http://127.0.0.1:8085")
	   		})
	   			.catch((err) => {
	   			})
	   	}
	   	// return () => {
	   	// }
	     }, []) */

	return (

		<Router history={ history }>
			<ConfigProvider locale={ isRu ? ruRU : byBY }>
				<Layout
					className={ (Bowser.name === 'Internet Explorer' || Bowser.name === 'Microsoft Edge') ? "ie-browser" : null }
					style={ {
						maxHeight : "100%",
						minHeight : "100vh"
					} }
				>
					<Navigation />
					<Layout
						className="main-middle-layout"
						style={ {
							backgroundColor: '#FFFFFF'
						} }
					>
						<Content
							style={ {
								display       : 'flex',
								flexDirection : 'column'
							} }
						>
							<ErrorBoundary
								isRu={ isRu }
							>
								{ roles.length !== 0 && <CustomBreadcrumb /> }

								<Routes />
							</ErrorBoundary>
						</Content>
					</Layout>
					<Footer />
				</Layout>
			</ConfigProvider>
		</Router>
	)
}
export default hot(App)
