const i18n = {
	header: {
		1 : "Ой",
		0 : "Ой"
	},
	message: {
		1 : "Страница не найдена",
		0 : "Старонка не знойдзена"
	},
	back: {
		1 : "Вернуться на главную страницу",
		0 : "Вярнуцца на галоўную старонку"
	}
}

export default i18n

